.contact-container {
    width: 100vw;

    color: white;
}

.contact-container-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 20px;
}

.contact-text-wrapper {
    text-align: center;
    padding: 60px;
}

.contact-h1 {
    font-size: 40px;
    margin: 0;
}

.contact-form-padding {
    width: 100vw;
    display: flex;
    justify-content: center;
}

.contact-form-container {
    width: 30%;
    height: 60%;
}

.contact-form-input-box {
    margin: 20px;
    padding: 12px;
    border-style: none;
    margin-inline: 0;
    background-color: rgb(240, 240, 240);
}

.contact-form-input {
    width: 100%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: none;
    overflow: hidden;
    font-family: 'Poppins';
    font-size: small;
    background-color: rgb(240, 240, 240);
}

.contact-form-input:focus-visible {
    outline: none;
    background-color: transparent;
}

.contact-form-name-inputs {
    display: flex;
    flex-direction: row;
}

.contact-form-input-name {
    width: 50%;
    margin-bottom: 0;
}

.contact-form-input-name-left {
    width: 50%;
    margin-bottom: 0;
    margin-right: 20px;
}

.contact-form-input-message {
    padding-bottom: 100px;
    max-width: 100%;
}

.contact-form-submit-button-container {
    display: flex;
}

.contact-form-submit-button {
    width: 160px;
    background-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(240, 240, 240);
    color: rgb(240, 240, 240);;
    border-radius: 8px;
    font-family: 'Poppins';
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.contact-form-submit-button:hover {
    background-color: rgb(240, 240, 240);
    border-color: rgb(240, 240, 240);
    color: rgb(20, 20, 20);
}

.contact-social-media-text {
    padding-top: 40px;
    padding-bottom: 20px;
}

.contact-social-link {
    color: white;
    font-size: 48px;
    padding-inline: 20px;
}

@media (max-width: 800px) {
    .contact-form-container {
        width: 90%;
    }
    
    .contact-text-wrapper {
        padding: 0;
    }

    .contact-social-media-text {
        padding-top: 20px;
        padding-bottom: 10px;
    }
}   

