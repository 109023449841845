/*main container*/
.audio-player-container {
    height: 600px;
    width: 1000px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 400px;
    margin: 0 auto;
}

/*individual tracks*/
.audio-player-track-box {
    padding-inline: 20px;

    display: flex;
    flex-direction: row;

    background-color: #f7f7f7;
    cursor: pointer;
    border-radius: 8px;

    transition: background-color 0.3s, height 0.3s ease;
}
  
.audio-player-track-box:hover {
    background-color: #e5e5e5;
}

.audio-player-track-box-expanded {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/*expand track header*/
.audio-player-track-header {
    margin-left: 40px;
    height: 40px;
    width: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    white-space: nowrap;
    
    transition: width 0.4s ease, justify-content 0.4s ease;
}

.audio-player-track-header-expanded {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.audio-player-progress-bar {
    width: 0%;
    transition: width 0.3s ease;
}

.audio-player-progress-bar-expanded {
    width: 100%;
    margin-right: 20px;
    transition: width 0.3s ease;
}

/*play / pause button*/
.audio-player-play-pause-container {
    /*position*/
    position: absolute;
    margin-top: 10px;
    margin-left: 20px;
    
    /*draw box*/
    height: 20px;
    width: 20px;
    border-radius: 20px;
    background-color: #555;
    
    cursor: pointer;
}

.audio-player-play-pause-button {
    /*position*/
    position: absolute;
    transform: translate(85%, 50%);
    
    height: 10px;
    width: 8px;
    padding: 0;
    box-sizing: border-box;
    border-color: transparent transparent transparent #f1f1f1;
    background-color: transparent;
    
    transition: 100ms all ease;
    will-change: border-width;

    cursor: pointer;
}

.audio-player-play-pause-button-paused {
    /*draw icon*/
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-radius: 0.5px;
}

.audio-player-play-pause-button-playing {
    /*draw icon*/
    border-style: double;
    border-width: 0px 0 0px 8px;
    border-radius: 0px;
    transform: translate(75%, 50%);
}

/*expand track description*/
.audio-player-track-details {
    height: 0;
    opacity: 0;
    transition: height 0.3s ease, opacity 0.3s 0.3s;
}

.audio-player-track-details-expanded {
    opacity: 1;
    height: 100px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-y: auto; 
}

.audio-player-track-details-collapsed {
    height: 0;
    opacity: 0;
    transition: height 0.3s ease, opacity 0s ease; 
}

/*html audio tag styles*/
.audio-player-track-details-expanded::-webkit-scrollbar {
    width: 8px;
}

.audio-player-track-details-expanded::-webkit-scrollbar-track {
    background: rgba(250,250,250,0);
}

.audio-player-track-details-expanded::-webkit-scrollbar-thumnb {
    background-color: rgba(0, 0, 0, 0.01);
    border-radius: 4px;
    transition: background-color 0.3s;
}

.audio-player-track-details-expanded::-webkit-scrollbar-thumnb:hover {
    background-color: rgba(0, 0, 0, 0.5);
}

audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-current-time-display,
audio::-webkit-media-controls-audio-slider,
audio::-webkit-media-controls-time-remaining-display {
    display: none;
}

audio::-webkit-media-controls-overflow-button-media-controls-overflow-button {
    display: none;
}

audio {
    filter: opacity(100%);
    width: 100%;
    height: 100%;
}

audio::-webkit-media-controls-enclosure {
    background-color: transparent;   
}

audio::-webkit-media-controls-panel {
    padding: 0;
}

audio::-webkit-media-controls-timeline {
    padding: 0;
    padding-right: 16px;
}

audio::-webkit-media-controls-volume-slider {
    background-color: transparent;
    color: transparent
}

@-moz-document url-prefix() {
    audio {
        /* Your general styles for audio */
        background-color: transparent;
    }
    
    /* Styling the track of the audio player */
    audio::-moz-range-track {
        background-color: #ddd;
        border-radius: 5px;
    }
    
    /* Styling the thumb of the audio player */
    audio::-moz-range-thumb {
        background-color: #333;
        border: 1px solid #222;
        border-radius: 50%;
        --thumb-size: 103px;
    }
}



/*mobile styles*/
@media (max-width: 800px) {
    .audio-player-container {
        max-width: 90vw;
    } 
}



  

  

  