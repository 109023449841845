.research-section-container-grainflocks {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
}

.research-section-container-aNST {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: black;
    overflow-x: hidden;
}

.research-section-content {
    width: 100vw;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.research-section-boid-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.research-section-title {
    margin: 0;
    font-size: 40px;
    color: white;
    padding: 60px;
}

.research-section-subtitle {
    font-size: larger;
}

.research-section-subtitle-aNST {
    font-size: larger;
    text-align: right;
}

.research-section-content-inner-grainflocks {
    margin: 0 auto;
    max-width: 1600px;
    display: flex;
    flex-direction: row;
}

.research-section-content-inner-aNST {
    margin: 0 auto;
    max-width: 1600px;
    display: flex;
    flex-direction: row;
}

.research-section-content-grainflocks-left {
    width: 50%;
}

.research-section-content-grainflocks-right {
    width: 50%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.research-section-text-block {
    padding: 60px;
}

.research-section-grainflocks-text {
    color: white;
}

.research-section-aNST-text {
    color: white;
    text-align: right;
}

.research-section-youtube-embed {
    display: flex;
}

.research-section-youtube-embed-size {
    width: 560px;
    height: 315px;
}

.research-section-content-aNST {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.research-section-content-aNST-left {
    width: 50%;
}

.research-section-content-aNST-right {
    width: 50%;
    float: right;
    display: flex;
    align-items: center;
    justify-content: center;
}

.research-section-container-ambisonics {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: black;
}

@media (max-width: 800px) {
    .research-section-container-grainflocks {
        min-height: 100vh;
    }
    
    .research-section-container-aNST {
        min-height: 100vh;
    }

    .research-section-content-grainflocks-left {
        width: 100%;
    }

    .research-section-content-grainflocks-right {
        width: 100%;
    }

    .research-section-content-inner-grainflocks {
        flex-direction: column;
    }

    .research-section-title {
        padding-bottom: 10px;
    }

    .research-text {
        font-size: 0.8rem;
    }

    .research-section-text-block {
        padding: 0px;
        margin: 0 auto;
        width: 90vw;
    }

    .research-section-youtube-embed-size {
        width: 95vw;
        height: 100%;
    }

    .research-section-content-inner-aNST {
        flex-direction: column-reverse;
    }

    .research-section-content-aNST-left {
        margin: 0 auto;
        width: 90vw;
        padding-block: 20px;
    }

    .research-section-text-block {
        margin-bottom: 20px;
    }

    .research-section-content-aNST-right {
        width: 100%;
    }

    .research-section-subtitle-aNST {
        text-align: left;
    }

    .research-section-aNST-text {
        text-align: left;
    }
}

/* Audio NST Display */

.audio-nst-display-logo {
    margin: 0;
    text-align: center;
    font-style: italic;
    font-size: xx-large;
    font-weight: bold;
    color: white;
    letter-spacing: 4px;
    transition: all 0.5s ease;
}

.audio-nst-display-logo:hover {
    letter-spacing: 12px;
}

.audio-nst-display-container {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-nst-display-box {
    height: 600px;
    width: 400px;
    border-radius: 20px;
    background: url(../assets/kubasi_background_image_2.webp);
    background-color: black;
    box-shadow: 0 0 16px #eeeeee0c;
    transform: translateY(0px);
    transition: all 0.2s ease;
}

.audio-nst-display-box:hover {
    box-shadow: 0 10px 16px #eeeeee15;
    transform: translateY(-10px);
}

.audio-nst-display-text {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-nst-display-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-inline: 20px;
}

.audio-nst-display-selectors {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: space-between;
    cursor: pointer;
}

.audio-nst-display-select-box {
    display: flex;
    flex-direction: row;
    width: 70%;
    padding: 20px;
    border-radius: 20px;
    border: 4px solid white;
    transition: all 0.3 ease;
}

.audio-nst-display-select-dropdown {
    padding-left: 20px;
    font-family: 'Poppins';
    color: white;
    outline: none;
    border: none;
    background-color: transparent;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.audio-nst-display-select-box:hover .audio-nst-display-select-dropdown {
    background-color: white;
    color: black;
}

.audio-nst-display-select-box:hover {
    background-color: white;
    color: black;
}

.audio-nst-display-play-pause-button {
    /*position*/
    position: absolute;
    transform: translate(85%, 50%);

    height: 10px;
    width: 8px;
    padding: 0;
    box-sizing: border-box;
    border-color: transparent transparent transparent #f1f1f1;
    background-color: transparent;

    transition: 100ms all ease;
    will-change: border-width;

    cursor: pointer;
}

.audio-nst-display-play-pause-button-paused {
    /*draw icon*/
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-radius: 0.5px;
}

.audio-nst-display-play-pause-button-playing {
    /*draw icon*/
    border-style: double;
    border-width: 0px 0 0px 8px;
    border-radius: 0px;
    transform: translate(75%, 50%);
}

.audio-nst-display-select-box:hover .audio-nst-display-play-pause-button {
    border-color: transparent transparent transparent #000000;
}

.audio-nst-display-texture-button-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative; 
}

.audio-nst-display-texture-button {
    border-radius: 20px;
    border: 4px solid white;
    font-family: 'Poppins';
    color: white;
    outline: none;
    background-color: transparent;
    padding-inline: 40px;
    padding-block: 10px;
    transition: all 0.3s ease;
    position: relative; 
    cursor: pointer;
}

.audio-nst-display-texture-loading-animation {
    height: 100%;
    display: flex;
    background-color: transparent;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding-inline: 29px;
    padding-block: 10px;
}

.audio-nst-display-texture-loading-animation::before,
.audio-nst-display-texture-loading-animation::after {
    content: '';
    position: absolute;
    border: 4px dotted white;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: loading-spin 1s linear infinite;
}

@keyframes loading-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.audio-nst-display-texture-button:hover {
    background-color: white;
    color: black;
}

.audio-nst-display-texture-loading-animation {
    border-color : black; 
}

.audio-nst-display-result-button-container {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.audio-nst-display-result-button {
    width: 70%;
    border-radius: 20px;
    border: 4px solid white;
    font-family: 'Poppins';
    color: white;
    outline: none;
    background-color: transparent;
    padding-inline: 40px;
    padding-block: 10px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.audio-nst-display-result-button:hover {
    background-color: white;
    color: black;
}

@media (max-width: 800px) {
    .audio-nst-display-box {
        height: 120%;
        width: 70%;
    }

    .audio-nst-display-logo {
        font-size: x-large;
    }

    .audio-nst-display-select-box {
        width: 80%;
        padding: 10px;
        border-radius: 16px;
    }

    .audio-nst-display-texture-button {
        padding-inline: 20px;
        padding-block: 5px;
        border-radius: 16px;
        margin-bottom: 10px;
    }

    .audio-nst-display-result-button {
        width: 80%;
        padding-inline: 20px;
        padding-block: 5px;
        border-radius: 16px;
    }

    .research-section-content-inner-aNST {
        max-width: 100%;
    }
}

/* Ambisonic Player */
.ambisonic-container {
    width: 20vw;
    height: 40vh;
    display: flex;
    flex-direction: column;
    background-color: white;
}
