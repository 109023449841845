.about-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about-content {
    margin: auto;
    max-width: 1000px;
    display: flex;
    flex-direction: column;
}

.about-title {
    font-size: 40px;
    margin: 0;
}

.about-subtitle {
    font-size: 28px;
    font-weight: bold;
    margin: 0;
}

.about-link {
    font-weight: 800;
    color: black;
    background: linear-gradient(#303030 0 0) var(--p, 0) / var(--p, 0) no-repeat;
    border-radius: 6px;
    transition: 0.4s, background-position 0s;
}

.about-link:hover {
    --p: 100%;
    color: #fff;
    padding-inline: 4px;
}

.about-link:focus-visible {
    outline: 0;
    --p: 100%;
    color: #fff;
    padding-inline: 4px;
}

.about-content-divider-padding {
    display: flex;
    justify-content: center;
    padding-inline: 40%;
}

.about-content-divider {
    height: 1px;
    width: 100%;
    background-color: #ccc; 
    margin-block: 40px;
}

.about-content-divider-bottom {
    height: 1px;
    width: 40%;
    background-color: #ccc; 
    margin-block: 40px;
}

.about-content-top {
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    max-width: 1100px;
}

.about-content-right {
    width: 50%;
    padding-inline: 50px;

    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.about-content-left {    
    width: 50%;
    height: 100%;
}

.about-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.about-content-bottom {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.about-content-bottom-left {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.about-content-bottom-left-text {
    max-width: 85%;   
}

.about-content-bottom-right {
    display: flex;
    flex-direction: column;
    width: 50%;
}

.about-content-list {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.about-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.about-contact-button-container {
    display: flex;
}

.about-contact-button {
    width: 200px;
    text-align: center;
    background-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(240, 240, 240);
    color: black;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease;
}

.about-contact-button:hover {
    background-color: rgb(20, 20, 20);
    border-color: rgb(20, 20, 20);
    color: rgb(240, 240, 240);
}

.about-content-list-box {
    background-color: #eee;
    border-radius: 20px;
    padding: 20px;
    padding-inline: 30px;
    width: 40%;
    transition: all 0.3s ease;
}

.about-content-list-box:hover {
    background-color: #e2e2e2;
    box-shadow: 0 0 18px #0000001c;
}

@media (max-width: 800px) {
    .about-container {
        height: 100%;
    }
    
    .about-content {
        width: 100vw;
    }

    .about-content-top {
        flex-direction: column-reverse;
        align-items: center;
        width: 100vw;
        padding-top: 4vh;
    }

    .about-content-right {
        align-items: center;
        justify-content: center;
        width: 90vw;
        padding: 0;
    }

    .about-content-left {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 0;
    }

    .about-text {
        font-size: 0.8rem;
    }

    .about-image {
        max-width: 90vw;
        padding-block: 40px;
    }
}