.portfolio-container {
    position: relative;
    top: 0;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portfolio-container-inner {
    position: relative;
    top: 32px;
}

.portfolio-text-wrapper {
    text-align: center;
    padding: 40px;
}

.portfolio-h1 {
    font-size: 40px;
    margin: 0;
}

.portfolio-content-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three columns */
    gap: 2px;
}

/*Individual portfolio items*/
.portfolio-content-item {
    position: relative;
    width: 320px;
    height: 180px;
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
}

/*Thumbnail Container*/
.portfolio-content-item-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*YouTube Embed Container*/
.portfolio-content-item-youtube-player {
    z-index: 1;
    opacity: 1;
    transition: opacity 0.3s ease;
} 

/*Thumbnail / YouTube Embed transition*/
.portfolio-content-item-fade-in {
    opacity: 1;
}

.portfolio-content-item-fade-out {
    opacity: 0;
}

.portfolio-content-item-delay-fade-in {
    transition: opacity 0.3s ease 1s;
}

.portfolio-content-item-delay-fade-out {
    transition: opacity 0.3s ease;
}


/*thumbnail image*/
.portfolio-content-item-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*thumbnail text overlay*/
.portfolio-content-item-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;  
    padding: 0px;
    box-sizing: border-box;
    opacity: 0; 
    transition: opacity 0.3s ease;
}

.portfolio-content-item:hover .portfolio-content-item-overlay {
    opacity: 1; 
}

.portfolio-content-item-title {
    font-weight: bold;
    font-size: 16px;
}

.portfolio-content-item-text {
    font-size: 16px;
    padding-inline: 10px;
}

.portfolio-content-item-text-link {
    font-weight: bold;  
    color: #a5a5a5;
}

@media (max-width: 800px) {
    .portfolio-content-grid {
        grid-template-columns: repeat(1, 1fr);
        justify-items: center;
        gap: 20px;
        padding-bottom: 40px;
    }
}