/* landing header/nav */
.header-main {
    background-color: transparent;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 100;
}

.header-inner {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 101;
}

.header-logo-wrapper {
    margin: 0;
}

.header-kubasi-logo {
    margin: 0;
    padding-left: 24px;
    font-weight: bolder;
    font-size: xx-large;
    color: rgb(240, 240, 240);
    cursor: pointer;
}

.header-nav-wrapper {
    display: flex;
    padding-right: 24px;
}

.header-nav-text {
    padding-inline: 20px;
    color: rgb(240, 240, 240);
    transition: color 1s;
    cursor: pointer;
}

.header-nav-text:hover {
    color: rgb(200,200,200);
    font-weight: bold;
}

.header-nav-mobile-button-open {
    display: none;
}

.header-nav-mobile-button-close {
    display: none;
}

@media (max-width: 900px) {
    .header-nav-wrapper {
        display: none;
    }

    .header-nav-mobile-button-open {
        display: flex;
        padding-right: 24px;
    }

    .header-nav-mobile-button-close {
        display: flex;
        padding-right: 24px;
    }
}

.header-nav-modal-hide {
    opacity: 0;
    height: 0;
    transition: opacity 0.3s ease;
}

.header-nav-modal {
    opacity: 1;
    z-index: 99;
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(6px);
    overflow-y: hidden;
    overflow: -moz-hidden-unscrollable;
}

.header-nav-modal-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* nav bar */
.section-nav-bar-container {
    position: sticky;
    z-index: 9999999;
    top: 0;
    width: 100vw;
    background-color: black;
}

.section-nav-bar-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-nav-bar-text {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px;
    padding-inline: 20px;
    color: rgb(240, 240, 240);
    transition: all 0.3s ease;
    cursor: pointer;
}

.section-nav-bar-text:hover {
    color: rgb(240, 240, 240);
    font-weight: bold;
}

.section-nav-bar-icon {
    display: flex;
    align-items: center;
    padding: 8px;
    margin-inline: 2px;
    height: 10px;
    width: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.section-nav-bar-mobile {
    display: none;
}

@media (max-width: 900px) {
    .section-nav-bar-inner {
        display: none;
    }

    .section-nav-bar-mobile {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.section-nav-bar-mobile-button {
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    outline: 0;
    padding-block: 6px;
}

.section-nav-bar-mobile-icon {
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: transparent;
    transition: transform 0.3s ease;
}

.section-nav-bar-mobile-icon-rotate {
    transform: rotate(-90deg);
}

.section-nav-bar-menu-hide {
    position: absolute;
    height: 0;
    overflow-x: hidden;
    transition: height 0.4s ease;
    opacity: 0;
}

.section-nav-bar-menu {
    opacity: 1;
    background-color: black;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.section-nav-bar-menu-wrapper {
    position: relative;
    top: -5%;
}

.section-nav-bar-menu-text {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 4px;
    padding-block: 14px;
    color: rgb(240, 240, 240);
}

.section-nav-bar-space {
    width: 20px;
}
