html {
	scroll-behavior: smooth;
	scroll-snap-type: y mandatory;
}

section {
	scroll-snap-align: start;
	min-height: 100vh;
}

body {
	font-family: 'Poppins';
	width: 100%;
	height: 100%;
	overflow-x: hidden;
}

.no-scroll {
	overflow-y: hidden;
}

a {
	text-decoration: none;
}

@media (max-width: 800px) {
	html {
		scroll-snap-type: none;
	}
}

/* * {
	border: 1px solid red
} */

/* -- Scroll Animations -- */
.scroll-animation-fade-in-up {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.scroll-animation-fade-in-up-visible {
    opacity: 1;
    transform: translateY(0);
}

.game-loading {
	width: 100vw;
	height: 100vh;
	background-color: black;
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
}

::-webkit-scrollbar {
	display: none;
}