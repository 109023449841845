.game-container {
    position:  relative;
    bottom: 0;
    margin: 0;
    z-index: 1;
}

.asteroids-game {
    position: absolute;
    top: 0;
    margin: 0;
    z-index: 1;
}

.game-text-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end; /* This aligns content to the bottom */
    z-index: 10000;
}

.game-text-position {
    width: 100vw;
    height: 100vh;
}

.game-text {
    color: white;
    margin: 0;
}

.game-title {
    color: white;
    margin: 0;
    font-size: 3rem;
    font-style: italic;
    font-weight: bold;
}

.game-text-box {
    /*
    border-style: solid;
    border-top: 0px;
    border-bottom: 0px;
    border-width: 1px;
    border-color: rgb(240, 240, 240);
    */
    
    padding: 10px;

    margin: 10px;
}

.game-info-text {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    opacity: 1;
}

.game-info-text-hide {
    opacity: 0;
}

.game-over-text {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s steps(3);
}

.game-over-text-show {
    opacity: 1;
}

.game-over {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    background-color: transparent;
}

.game-over-hide {
    opacity: 0;
    display: none;
}
  
.p5Canvas{
    height: 30px;
}

.game-start {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.game-start-hide {
    opacity: 0;
    display: none;
}

.game-start-button {
    margin: 20px;
    padding: 10px 20px;
    font-size: 16px;
    color: rgb(240, 240, 240);
    background-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(240, 240, 240);
    border-radius: 12px;
    cursor: pointer;
    z-index: 100;
    transition: background-color 0.3s, color 0s, border-radius 0.3s, padding 0.3s, font-size 0.3s;
    margin-block: 40px;
}

.game-start-button:hover {
    color: black;
    background-color: rgb(240, 240, 240);
}  

.game-paused {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 20px;
    border-radius: 10px;
    transition: all 0.3s ease;
    background-color: transparent;
}

.game-paused-hide {
    opacity: 0;
    display: none;
}

.game-paused-text-box {
    display: flex;
    flex-direction: row;
    padding: 10px;
}

.game-paused-text {
    padding-inline: 20px;
}

.game-pause-option-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.game-pause-option-button {
    width: 40%;
    background-color: transparent;
    border-radius: 4px;
    border: 0;
    color: white;
    font-size: 18px;
    padding: 6px;
    margin: 6px;
    cursor: pointer;
}

.game-pause-option-button:hover {
    background-color: white;
    color: black;
}

.game-pause-option-button:focus-visible {
    background-color: white;
    color: black;
    outline: 0;
}

.game-control-instructions {
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-block: 20p
}

.game-control-instuctions-text {
    margin-inline: 8px;
}