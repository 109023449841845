.showreel-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
}

.showreel-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.showreel-play-button-hover-area {
    padding-block: 100px;
    padding-inline: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.showreel-play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px 40px;
    font-size: 16px;
    color: rgb(240, 240, 240);
    background-color: transparent;
    border-style: solid;
    border-width: 4px;
    border-color: rgb(240, 240, 240);
    border-radius: 8px;
    cursor: pointer;
    z-index: 100;
    transition: background-color 0.3s, color 0s, border-radius 0.3s, padding 0.3s, font-size 0.3s;
}

.showreel-play-button-triangle {
    clip-path: polygon(50% 70%, 25% 30%, 75% 30%);
    transform: rotate(90deg);
    transition: all 0.3s ease;
}

@media (min-width: 800px) {
    .showreel-play-button-hover-area:hover .showreel-play-button {
        font-size: 0px;
        background-color: transparent;
        color: transparent;
        padding: 40px 40px;
        border-radius: 150px;
        border-color: rgb(240, 240, 240);
        width: 0;
        height: 0;
    }


    .showreel-play-button-hover-area:hover .showreel-play-button-triangle {
        position: absolute;
        top: 2px;
        left: 0;
        background-color: rgb(240, 240, 240);
        width: 84px;
        height: 84px;
        transform: rotate(0deg);
    }
}

@media (max-width: 800px) {
    .showreel-play-button {
        padding: 10px 20px;
    }

    .showreel-play-button-hover-area {
        width: 100%;
        padding: 0;
    }
}