.sound-container {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sound-container-inner {
    position: relative;
    top: 32px;
}

.sound-text-wrapper {
    text-align: center;
    padding: 40px;
}

.sound-h1 {
    font-size: 40px;
    margin: 0;
}
